import * as AlertDialogPrimitive from '@radix-ui/react-alert-dialog';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { FC, ReactNode } from 'react';
import create from 'zustand';
import shallow from 'zustand/shallow';
import Button from './Button';
import ScrollContainer from './ScrollContainer';

interface AlertDialogStore {
  isOpen: boolean;
  title?: string;
  description?: string | ReactNode;
  action?: () => void;
  trigger: (
    title: string,
    description: string | ReactNode,
    action?: () => void,
  ) => void;
  close: (isOpen: boolean) => void;
}

const INITIAL_STATE: Pick<
  AlertDialogStore,
  'isOpen' | 'title' | 'description' | 'action'
> = {
  isOpen: false,
  title: undefined,
  description: undefined,
  action: undefined,
};
const useAlertDialog = create<AlertDialogStore>((set) => ({
  ...INITIAL_STATE,
  trigger: (title, description, action) =>
    set(() => ({
      isOpen: true,
      title,
      description,
      action,
    })),
  close: () => set(() => INITIAL_STATE),
}));

export const useAlertDialogTrigger = () =>
  useAlertDialog((state) => state.trigger);

const AlertDialog: FC = () => {
  const [isOpen, title, description, action, close] = useAlertDialog(
    (state) => [
      state.isOpen,
      state.title,
      state.description,
      state.action,
      state.close,
    ],
    shallow,
  );

  return (
    <div className="z-10">
      <AlertDialogPrimitive.Root open={isOpen} onOpenChange={close}>
        <AlertDialogPrimitive.Portal>
          <AlertDialogPrimitive.Overlay
            className="pointer-events-none fixed inset-0 z-20 bg-gray-700/50"
            asChild
          >
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ type: 'spring', duration: 0.5 }}
            />
          </AlertDialogPrimitive.Overlay>
          <AlertDialogPrimitive.Content
            className={clsx(
              'fixed top-1/2 left-1/2 z-40 flex h-[80vh] w-full max-w-[60%] translate-x-[-50%] translate-y-[-50%] flex-col overflow-visible rounded bg-white pt-[3rem] shadow-lg',
            )}
          >
            <AlertDialogPrimitive.Title className="bg-primary z-index-40 fixed top-0 flex h-[3rem] w-full items-center px-4 py-2 text-white">
              <span className="grow text-ellipsis whitespace-nowrap">
                {title}
              </span>
            </AlertDialogPrimitive.Title>
            <AlertDialogPrimitive.Description asChild>
              <div className="relative h-full w-full grow overflow-hidden">
                <ScrollContainer>
                  {typeof description === 'string' ? (
                    <p className="p-4">{description}</p>
                  ) : (
                    description
                  )}
                </ScrollContainer>
              </div>
            </AlertDialogPrimitive.Description>
            <div className="flex w-full justify-center gap-4 p-4">
              <Button
                size="sm"
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();

                  if (action) {
                    action();
                  }

                  close(false);
                }}
              >
                OK
              </Button>
            </div>
          </AlertDialogPrimitive.Content>
        </AlertDialogPrimitive.Portal>
      </AlertDialogPrimitive.Root>
    </div>
  );
};

export default AlertDialog;
